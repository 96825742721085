export default {
  translations: {
    header: {
      aboutMenu: {
        support: 'Apoyo',
        help: 'Ayuda',
        about: 'Acerca',
      },
    },
    screens: {
      receivement: {
        follow_up: 'Acompañamiento',
        receivement: 'Recibo',
        requestType: 'Tipo de Solicitud',
        order: 'Pedir',
        invoice: 'Factura Fiscal',
        status: 'Estado',
        series: 'Serie',
        product: 'Producto',
        supplier: 'Proveedor',
        initialDate: 'Fecha Inicio',
        finalDate: 'Fecha Final',
        details: 'Detalles',
        generateConference: 'Generar Conferencia',
        endDivergence: 'Termina con la Divergencia',
        confirmEndDivergence:
          '¿Estás seguro de que quieres terminar con la divergencia?',
        orderFinisherEnd: 'Pedido completado con discrepancia',
        progress: 'Progresso',
        actions: 'Comportamiento',
        dateApproved: 'Fecha Apro',
        amountSku: 'Ctdd. SKU',
        amount: 'Ctdd',
        dateReceivement: 'Fecha Rec',
        dateEnd: 'Fin Rec',
        alertSeach:
          'Por favor, introduzca fechas con una diferencia máxima de 30 días o filtre por pedido y factura',
        successFilter: 'resultados encontrados',
        resultsNotFound: 'Resultados no encontrados',
        document: 'Documento',
        receiptDate: 'Fecha de recepción',
        endDate: 'Fecha final',
        enterProduct: 'Introduce el producto',
        enterBox: 'Entrar en la caja',
        enterPallet: 'Entrar en la paleta',
        dateChecked: 'Fecha comprobada',
        size: 'Tam.',
        quantity: 'Qtd. NF',
        divergent: 'Divergencia',
        dateReceipt: 'Fecha Rec.',
        successfullyReversed: 'revertido con éxito',
        reversedSuccessfully: 'Recibo revertido con éxito',
        isReverse: '¿Quieres revertir el recibo',
        reverse: 'Revertir',
        youLogin: 'Su nombre de usuario',
        password: 'Contraseña',
        youPassword: 'Su Contraseña',
        autenticateMsg: 'Ups ¡Comprueba tu nombre de usuario y/o contraseña!',
        notLeaderMsg: '¡Solo el líder puede autorizar el reembolso!',
        revertTitleModal: 'Autenticación de líder',
      },
      status: {
        canceled: 'Cancelado',
        divergent: 'Divergente',
        inProgress: 'En proceso',
        finished: 'Finalizado',
        completedDivergent: 'Divergente Completado',
        integrated: 'Integrada OK',
        integratedDivergent: 'Integrada Divergente',
        invoiceDivergent: 'Factura Divergente',
        pending: 'Pendente',
      },
      cardsFilters: {
        results: 'Resultados',
        finished: 'Finalizado',
        completedDivergent: 'Divergente Completado',
        inProgress: 'En Proceso',
        divergent: 'Divergente',
        pending: 'Pendiente',
        totalProgress: 'Progresso Total',
        create: 'Creado',
        planned: 'Planificado',
        progress: 'Progreso',
        resupply: 'Reabastecimiento',
        completedShipment: 'Envío Completado',
        finishedDivergence: 'Terminado C/ Divergencia',
        insufficientStock: 'Existencias Insuficientes',
        awaitingIntegration: 'En espera de Integración',
        checkedQuantity: 'Cantidad Marcada',
        pickingQuantity: 'Cantidad Picking',
        totalAmount: 'Cantidad total',
        startDate: 'Fecha Inicio Separación',
        startTime: 'Separación Inicio Tiempo',
        endDate: 'Fecha Finalización del Envío',
        endTime: 'Hora Finalización Embarque',
        awaitingBilling: 'En Espera de Facturación',
        awaitingShipment: 'En Espera de Envío',
        awaitingConference: 'Conferencia en Espera',
        shipmentFinished: 'Envío Terminado',
        awaitingRelease: 'En Espera de Lanzamiento',
        divergentQuantity: 'Cantidad divergente',
        amountReceived: 'Cantidad recibida',
        waitingConference: 'Esperando conferencia',
        waitingShipment: 'Esperando embarque',
        orderCancelled: 'Orden cancelada',
        waitingRelease: 'En espera de liberación',
        waitingScreening: 'Esperando la proyección',
        screeningCompleted: 'Evaluación completada',
        update: 'Para actualizar',
        delivered: 'Entregado',
      },
      structureAdresses: {
        type: 'Tipo',
        description: 'Descripción',
        isActive: '¿Está activo?',
        noFound: 'No se encontró ninguna dirección',
        adreessesFound: 'direcciones encontradas',
        structureEditSuccesses: 'Estructura editada con éxito',
        successDeleteRecordes: 'Registro eliminado con éxito',
        notFoundRecordes: 'No se encontraron registros.',
        wasFound: 'Fue encontrado',
        records: 'registros',
        someError: 'Algo salió mal con la solicitud',
        structureCreate: 'Structure created successfully!',
        structure: 'Estructura',
        titleScreen: 'Crear estructura',
        newStrucutureBtn: 'Nueva Estructura',
        create: 'Crear',
        createStructureTitle: 'Crear estructura',
        edit: 'Para editar',
        successfullyDeleted: '¡Eliminado con éxito!',
        isDeleted: '¿Quieres borrar?',
        status: 'Estado',
        actions: 'Comportamiento',
        delete: 'Borrar',
        statusTrue: 'Activo',
        statusFalse: 'Inactivo',
      },
      address: {
        deposit: 'Depósito',
        type: 'Tipo',
        status: 'Estado',
        adresses: 'Dirección',
        toWalk: 'Andar',
        road: 'Camino',
        modules: 'Módulos',
        level: 'Nivel',
        position: 'Posición',
        resulpply: 'Reabastecimiento',
        capacity: 'Capacidad',
        resu: 'Reab.',
        actions: 'Comportamiento',
        delete: 'Borrar',
        edit: 'Para editar',
        editStatus: 'Editar Estado',
        newAddress: 'Nueva Dirección',
        storage: 'Almacenamiento',
        notFound: 'No hay registros de direcciones',
        foundResults: 'resultados encontrados',
        error: 'Error',
        branch: 'Subsidiaria',
        shippingCompany: 'Compañía de envios',
        branchsNotName: 'No hay sucursales con ese nombre.',
        editedSuccessfully: '¡Editado con éxito!',
        saveButtonTitle: 'Ahorrar',
        notLeave: '¡No deje el campo de estado vacío!',
        updateStatus: '¡Estado actualizado con éxito!',
        deleteSuccess: 'Eliminado con éxito',
        isDelete: '¿Está seguro de que desea eliminar la dirección',
        deleteAddress: 'Dirección eliminada',
        createAddressTitle: 'Crear Dirección',
        createAddressSuccess: '¡Creado con éxito!',
        addressExist: '¡Dirección existente!',
        importFile: 'Importar Archivo',
        importSuccess: '¡Archivo importado con éxito!',
        uploadFile: 'Subir archivo',
        choiceFile: 'Elija el archivo para upload.',
      },
      logAddress: {
        product: 'Producto',
        address: 'Dirección',
        initialDate: 'Fecha Inicio',
        finalDate: 'Fecha Final',
        box: 'Caja',
        pallet: 'Paleta',
        type: 'Tipo',
        noRecordes: '¡No se encontraron registros!',
        logStorage: 'Log Almacenamiento',
        quantityPalete: 'Cantidad Palets',
        quantityBox: 'Cantidad Caja',
        quantity: 'Cantidad',
        manufacturer: 'Fabricante',
        descriptionProduct: 'Descripción del producto',
        dateStorage: 'Fecha de Almacenamiento',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por producto o dirección',
      },
      orderFollowUp: {
        title: 'Orden',
        followUp: 'Acompañamiento',
        orderType: 'Tipo Orden',
        client: 'Cliente',
        period: 'Período',
        clientBranch: 'Client/Subsidiaria',
        receive: 'Recibió',
        planning: 'Planificación',
        finished: 'Finished',
        endShipment: 'Finalizar Envío',
        resultsNotFound: 'Resultados no encontrados.',
        recordsFound: 'Fue encontrado',
        clientAndBranch: 'Cliente/Subsidiaria',
        quantity: 'Cantidad',
        received: 'Recibió',
        check: 'Controlar',
        color: 'Color',
        orderProgress: 'Progreso del Pedido',
        branchsNotName: 'No hay sucursales con ese nombre.',
        printing: 'Impresión',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por ordem',
        separationStart: 'Fecha de inicio de la separación',
      },
      reverseOrder: {
        errorTheRequest: 'Error: La solicitud',
        thisStatusCreate: 'esto ya es como el estado creado!',
        orderStatus: 'Estado del orden',
        changedSuccessfully: 'changed successfully!',
        noRecordesFound: 'No se encontraron registros.',
        reverse: 'Contrarrestar',
        wave: 'Ola',
        editStatusTitle: 'Editar Estado - Orden:',
        dateOrder: 'Fecha Orden',
        quantityPieces: 'Ctd de Piezas',
        newWave: 'Nueva Ola',
        alertSearch:
          '¡Ingrese fechas con una diferencia máxima de 30 días o filtre por orden u ola!',
      },
      createWave: {
        included: 'incluido',
        onWave: 'en la ola',
        notIncluded: 'no incluido',
        branchsNotName: 'No hay sucursales con ese nombre.',
        picking: 'Cosecha',
        createWave: 'Crear ola',
        taxUnit: 'UT',
        customerBranch: 'Cliente/Sucursal',
        quantityPieces: 'Qtd Piezas',
        newWave: 'Nueva Ola',
        selectedOrder: 'pedido(s) seleccionado(s)',
        priority: 'Prioridad',
        splitVolume: 'Volumen Dividido?',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      followPicking: {
        branchsNotName: 'No hay sucursales con ese nombre.',
        picking: 'Cosecha',
        editUser: 'Editar Usuario',
        changeUser: 'Cambiar Usuario',
        editUserSuccess: 'Usuario editado con éxito',
        followPicking: 'Seguimiento de la Conferencia',
        separationFollowUp: 'Seguimiento de separaciones',
        priority: 'Prioridad',
        customerBranch: 'Cliente/Sucursal',
        quantityPieces: 'Qtd. Piezas',
        quantityPicking: 'Qtd. Cosecha',
        quantityChecked: 'Qtd. Tomprobado',
        dateOrder: 'Fecha Orden',
        dateFinal: 'Fecha Final',
        document: 'Documento',
        startDate: 'Fecha de inicio:',
        finalDate: 'Fecha final:',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por ola y orden.',
      },
      pickingConference: {
        product: 'Producto:',
        isResult: 'comprobado con éxito. Orden:',
        picking: 'Cosecha',
        conference: 'Conferencia',
        conferenceDetails: 'Detalle de la Conferencia',
        finalize: 'Finalizar!',
        conferenceFinished: '¡Conferencia finalizada!',
        boxAlreadyAddressed: 'Caja ya direccionada!',
        boxNotExist: '¡La caja no existe!',
        boxNotBelong: '¡La caja no pertenece al pedido!',
        invalidBarcode: 'Código de barras no válido',
        validatedSucces: '¡Caja y EAN validados con éxito!',
        quantityExceeded: '¡Cantidad excedida!',
        insertBox: '¡Por favor, inserte una caja para recoger!',
        endConference: '¿Está seguro de que desea finalizar la conferencia?',
        barCode: 'Cód. barra',
        code: 'Código de Barras',
        box: 'Caja',
        check: 'Controlar',
        conferred: 'Conferido',
        qty: 'Qtd.',
        boxType: 'Tipo de caja',
        bathModalTitle: 'Lote',
        selectBath: 'Seleccionar el Lote',
        validity: 'Validez',
        checkedProduct: 'Producto Verificado',
        alreadyPackage: '¡Producto ya empaquetado!',
        invalidBarcodeByProduct: '¡Código no válido para este pedido!',
        notBarcode: '¡Rellena el código de barras!',
        grouping: 'Agrupamiento',
        depositCod: 'Cod. Depósito',
        despositDesc: 'Desc. Depósito',
        addressCode: 'Códi. Dirección',
        addressDesc: 'Desc. Dirección',
        quantityVisit: 'Cant. Visitas',
        quantityParts: 'Cant. Partes',
        reportVisit: 'Visíta Picking',
        confirmRevertMsg: 'Confirmar reembolso del producto',
      },
      transferenceFollowUp: {
        transfer: 'Transferir',
        origin: 'Origen',
        destiny: 'Destino',
        unit: 'Unidad',
        type: 'Tipo',
        transferDate: 'Fecha de Transferencia',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por unidad.',
      },
      expeditionFollowUp: {
        expedition: 'Expedición',
        query: 'Consulta',
        state: 'Estado',
        dispatchDate: 'Fecha de Envio',
        customerBranch: 'Cliente/Sucursal',
        dispatchUser: 'Usuario Despacho',
        codeOrder: 'Cód. Orden',
        codeExpedition: 'Cód. Expedición',
        validProduct: 'Producto Valido',
        startDate: 'Fecha de Inicio del Envío',
        endDate: 'Fecha de Finalización del Envío',
        branchsNotName: 'No hay sucursales con ese nombre.',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      boarding: {
        boarding: 'Embarque',
        packingList: 'Lista Embalaje',
        customerBranch: 'Cliente/Sucursal',
        invoiceQuantity: 'Qtd. NF',
        volumeQuantity: 'Qtde. Volumen',
        exportButton: 'Exportar listas de embalaje en PDF',
        requestCode: 'Código orden',
        orderType: 'Tipo de orden',
        packingDetails: 'Detalles Empaque',
        recordFound: 'registros encontrados',
        agent: 'Agente',
        enterAgent: 'Ingrese el agente',
        agentError: 'Error. ¡Conéctate con tu agente!',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por lista de empaque.',
      },
      manualOrder: {
        manualOrder: 'Registro manual',
        fileUpload: 'Subir archivo',
        register: 'Registro',
        requireFilds: 'Rellene los campos obligatorios',
        receipt: 'Recibo:',
        successfullyCreated: 'creado con exito!',
        order: 'Order:',
        created: 'creado!',
        operation: 'Operación',
        chooseOperation: 'Elige la operación',
        prohibited: 'Prohibido',
        exit: 'Salida',
        chooseType: 'Elige un tipo',
        enterInvoice: 'Introduce la factura',
        series: 'Serie',
        date: 'Date',
        branchCustomer: 'Código cliente ERP/Sucursal',
        carrierCode: 'Código de transportista',
        supplier: 'Proveedor',
        chooseSupplier: 'Elegir proveedor',
        items: 'Elementos',
        deletedItems: 'Objetos eliminados',
        deletedItem: 'Elemento eliminado',
        withSuccess: 'con éxito',
        ordem: 'Orden',
        delete: 'Borrar',
        validData: 'Introduce datos válidos',
        addedSuccessfully: 'gregado exitosamente!',
        nameAndCode: 'No hay productos con este nombre/código',
        chooseProduct: 'Elige un producto',
        enterAmount: 'Introduce una cantidad',
        toAdd: 'Para agregar',
        chooseUpload: 'Elija el archivo para cargar.',
        liftFile: 'Subir archivo',
        chooseRegistration: 'Elija el formulario de registro',
      },
      parameters: {
        parameters: 'Parámetro',
        administration: 'Administración',
        successfullyEdited: '¡Estructura editada con éxito!',
        enterParemeter: 'Por favor ingrese el parámetro',
        parametersDescription: 'Por favor ingrese la descripción del parámetro',
        value: 'Valor',
      },
      registerProduct: {
        searchProduct: '¡Busca un producto!',
        thereProducts: 'No hay productos',
        productsFound: 'productos encontrados',
        register: 'Registro',
        productCode: 'Código del producto',
        marked: 'Marcado',
        unchecked: 'Desenfrenado',
        productEdited: 'Producto editado con éxito',
        active: 'Activo',
        level: 'Nivel',
        height: 'Altura',
        width: 'Ancho',
        length: 'Comprimento',
        weight: 'Peso',
        component: 'Componente',
        brandCode: 'Código de marca',
        receipt: 'Recibo',
        receiptInDays: 'Recibo (en días)',
        exit: 'Salida',
        parameters: 'Parámetros',
        marketingStructure: 'Estructura de mercadeo',
        departureInDays: 'Salida (en días)',
        batchControl: 'Control Lotes',
        controlsValidity: 'Validez de los Controles',
        controlsReceipt: 'Recepción de lotes Controles',
        receiptValidity: 'Vigencia del recibo Controles',
        constrolStorage: 'Lote de almacenamiento Controles',
        storageValidity: 'Validez de almacenamiento Controles',
        constrolSeparation: 'Separación de lotes Control',
        separationValidity: 'Vigencia de Separación Controles',
        checkControl: 'Compruebe el control Lotes',
        validityCheck: 'Comprobación de validez Control',
        notFieldsEmpty: 'No dejes campos vacíos',
        colorOrDescription: 'Color o Descripción',
        colorDesc: 'Color/Desc',
        delete: 'Borrar',
      },
      produtivityConference: {
        produtivity: 'Productividad',
        conference: 'Conferencia',
        name: 'Nombre',
        summaryTable: 'Tabla de Resumen',
        analyticalTable: 'Mesa Analítica',
        totalHours: 'Horas totales',
        ordersTime: 'Tiempo de pedidos',
        amountOrder: 'Qtd. Pedido',
        amountPending: 'Qtd. Pendiente',
        amountPackaged: 'Qtd. Empaquetado',
        addressesTime: 'Qtd. Direcciones Hora',
        amountAddresses: 'Qtd. Direcciones',
        productTime: 'Tiempo del producto',
        amountProduct: 'Qtd. Producto',
        researchDate: 'Fecha de Investigación',
        fisrtMessage: 'resultado(s) se encontraron en el análisis y',
        lastMessage: 'resultado(s) encontrado(s) en sintético',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por nombre.',
        avgHours: 'H. Promedio Ini.',
        endAvgHours: 'H. Promedio Fin',
        totalHourOrders: 'Pedidos Horas T.',
        quantityHours: 'Qtd. Pedidos Horas T.',
        quantityAddress: 'Qtd. Dir. Hora T.',
        totalAddress: 'Qtd. Direcciones T.',
        productHours: 'Producto Hora T.',
        quantityHoursTotal: 'Qtde. Producto T.',
        printMsg: 'Producto 100% comprobado, ¿quieres imprimir?',
        store: 'Almacenar',
      },
      produtivityStorage: {
        notFieldEmpty: 'No dejes este campo vacío',
        choiceDate: '¡Elige una fecha de inicio y finalización!',
      },
      reportsStock: {
        boarding: 'Embarcar',
        brand: 'Marca',
        address: 'Dirección',
        descriptionProduct: 'Descripción del Producto',
        codeRequest: 'Código de Solicitud',
        reserved: 'Reservado',
        available: 'Disponible',
        inbound: 'Inbound',
        outBound: 'OutBound',
        destinationStore: 'Tienda de destino',
        reservation: 'Reserva',
      },
      invoiceDivergent: {
        reports: 'Informes',
        orderDivergent: 'Orden Divergente',
        series: 'Serie',
        date: 'Fecha',
        invoiceQuantity: 'Qtd. Nota Fiscal',
        orderQuantity: 'Qtd. Orden',
        alertSearch:
          'Ingrese fechas con una diferencia máxima de 30 días o filtre por pedido.',
      },
      ressupply: {
        ressupply: 'Reabastecimiento',
        origin: 'Orige',
        destiny: 'Destino',
        priority: 'Prioridad',
        reports: 'Informes',
      },
      insufficient: {
        insufficient: 'Insufficient',
        reports: 'Informes',
        priority: 'Prioridad',
      },
      separation: {
        reports: 'Informes',
        separation: 'Separación',
        quantityPickink: 'Qtd. Cosecha',
        quantityOrder: 'Qtd. Orden',
        series: 'Serie',
        nameClient: 'Nombre Cliente',
        codeClient: 'Cod. Cliente',
        codShippingCompany: 'Cod. Compañía de envios',
        descriptionAddress: 'Desc. Dirección',
        billingIssue: 'Problema de facturación',
        billingDate: 'Fecha de facturación',
        homeSeparation: 'Separación de viviendas',
        endSeparation: 'Separación final',
      },
      conference: {
        reports: 'Informes',
        conference: 'Conferencia',
        quantityConference: 'Qtd. Conferencia',
        quantityOrder: 'Qtd. Orden',
        series: 'Serie',
        nameClient: 'Nombre Cliente',
        integrationDate: 'Fecha de Integración',
        homeConference: 'Inicio Conferencia',
        endConference: 'Fin de la conferencia',
        finalize: 'Finalizar',
        station: 'Station',
        selectStation: 'Seleccione la Estación',
        errorMessage:
          '¡Ups! Asegúrese de que todos los pedidos y estaciones estén llenos',
      },
      reportReceipt: {
        receipt: 'Recibo',
        batch: 'Lote',
        enterBach: 'Ingrese al lote',
        reports: 'Informes',
        integrationDate: 'Fecha de Integración',
        series: 'Serie',
        enterSeries: 'Entra en la serie',
        alertSearch:
          'Introduzca fechas con una diferencia máxima de 30 días o filtre por pedido o factura.',
      },
      dashboard: {
        awaitingBilling: 'En Espera de Facturación',
        awaitingIntegration: 'En espera de Integración',
        conference: 'Conferencia',
        ddressesUsed: 'Direcciones utilizadas',
        stock: 'Existencias',
        expedition: 'Expedition',
        insufficient: 'Insuficiente',
        requests: 'Peticiones',
        pickingProgress: 'Selección divergente',
        divergentPicking: 'Divergent Picking',
        plannedPicking: 'Recolección Planificada',
        resupply: 'Reabastecimiento',
      },
      reportStock: {
        stored: 'Almacenado',
        shipped: 'Enviado',
        invoiced: 'Facturado',
      },
      pack: {
        packCreatSuccess: 'Paquete creado exitosamente',
        pack: 'Crear Paquete',
        validatedSucces: '¡Caja y EAN validados con éxito!',
        errorValidated:
          'Error. ¡El color y el producto son diferentes de los que ya figuran en la lista!',
      },
      streetOccupation: {
        totalOccupation: 'Total Ocupado',
        occupationStreet: 'Ocupación de la calle',
        occupation: 'Ocupado',
        empty: 'Vacío',
      },
      commom: {
        storage: 'Almacenamiento',
        detail: 'Detalle',
        details: 'Detalles',
        actions: 'Comportamiento',
        user: 'Usuario',
        order: 'Orden',
        status: 'Estado',
        initialDate: 'Fecha Inicio',
        startTime: 'Hora de Inicio',
        finalDate: 'Fecha Final',
        endTime: 'Hora de Finalización',
        totalTime: 'Tiempo Total',
        shippingCompany: 'Compañía de envios',
        branch: 'Subsidiaria',
        progress: 'Progreso',
        records: 'registros',
        product: 'Producto',
        descriptionProduct: 'Descripción del producto',
        box: 'Caja',
        pallet: 'Paleta',
        grid: 'Grade',
        size: 'Tamaño',
        invoice: 'Nota fiscal',
        recordsFound: 'Fue encontrado',
        client: 'Cliente',
        edit: 'Editar',
        saveButtonTitle: 'Ahorrar',
        orderType: 'Tipo Orden',
        resultsNotFound: 'Resultados no encontrados.',
        period: 'Período',
        orderDate: 'Fecha Orden',
        yes: 'Sí',
        no: 'No',
        create: 'Crear',
        followUp: 'Hacer un Seguimiento',
        wave: 'Ola',
        color: 'Color',
        quantity: 'Cantidad',
        toClean: 'Limpiar',
        search: 'Buscar',
        finalize: 'Finalizar',
        code: 'Código',
        successFilter: 'resultados encontrados',
        description: 'Descripción',
        error: 'Error',
        branchsNotName: '¡No hay sucursales con ese nombre!',
        toAdd: 'Para agregar',
        deposit: 'Depósito',
        level: 'Nivel',
        stock: 'Existencias',
        reports: 'Informes',
        dashboard: 'Panel de control',
        autenticate: 'Autenticación',
        seller: 'Vendedor',
        formPayment: 'Forma de pago',
        total: 'Total',
        barCode: 'Código de barras',
        enterBarCode: 'Introduce el código de barras',
        print: 'Impresora',
        printMsg: 'Impresión enviada. ¡Por favor verifique!',
        cage: 'Jaula',
        expirationData: 'Fecha de validez',
        quantityDivergente: 'Qtd. Divergente',
        storageRequest: 'Solicitud de Almacenamiento',
        quantityPack: 'Qtd. Pack',
        totalParts: 'Partes totales',
        alertAllFields: '¡Rellena todos los campos!',
        class: 'Clase',
        model: 'Modelo',
        selectStreet: 'Seleccione la calle',
        conect: 'Conectar',
        observation: 'Observación',
        heatMap: 'Mapa de Calor',
      },
      integration: {
        title: 'Supervisión',
        subTitle: 'Procesos',
        process: 'Proceso',
        last_sync_at: 'Última ejecución',
        last_sync_status: 'Éxito en la última ejecución',
        next_sync_at: 'Siguiente ejecución',
        running: 'Corriendo',
        stopped: 'Interrumpido',
        error: 'Error',
      },
      followAge: {
        daysInStock: 'Días en stock',
        tenDays: '10 Días',
        twentyDays: '20 Días',
        thirtyDays: '30 Días',
      },
      followWave: {
        followWave: 'Seguimiento de olas',
        amountOrder: 'Qtde. Pedidos',
        amountProduct: 'Qtde. Productos',
        waveDate: 'Ola de Fecha',
      },
      initialIntegration: {
        module: 'Módulo',
        autorization: 'Autorización',
        inventoryDate: 'Fecha de Inventario',
        createdDateInventory: 'Fecha de Creación',
        selectFile: 'Select the file',
        newInventoryTitle: 'Nuevo inventario',
        inventory: 'Inventario',
        inventoryCode: 'Código Inventario',
        home: 'Hogar',
        selectClass: 'Seleccionar Clase',
        detailInventory: 'Detalles Inventario',
        firstCollect: 'Primera colección',
        lastCollectt: 'Última Colección',
        message: 'Mensaje',
        errorMessage:
          '¡Error al crear una intención en las direcciones siguientes!',
        notFoundMessage:
          'Resultados no encontrados. ¡Compruebe el filtro o el inventario seleccionado!',
        selectInventory: '¡Seleccione un inventario!',
      },
      scoreInventory: {
        score: 'Puntaje',
        bar: 'Bar',
        brand: 'Marca',
        reference: 'Referencia',
        notScoreMsg: '¡Este Inventario no ha comenzado a contar!',
        totalAddresses: 'Direcciones Totales',
        firstCount: 'Primer conteo',
        secondCount: 'Segundo conteo',
        thistCount: 'Tercer recuento',
        consolidated: 'Consolidado',
        countedAddresses: 'Direcciones contadas',
        itemsCounted: 'Artículos contados',
        skusCounted: 'SKUs Contados',
        totalConsolidated: 'Total Consolidados',
        notFoundAddress: '¡El inventario no contiene direcciones!',
      },
      finallyInventory: {
        finallyInventory: 'Finalizar Inventario',
        reopenInventory: 'Reabrir Invetario',
        updateStock: 'Actualizar stock de WMS',
        updateMessage: '¡El stock de WMS se actualizó exitosamente!',
        warnningMessage:
          '¿Estás seguro de querer actualizar el stock del WMS con el Inventario consolidado?',
      },
      divergentInventory: {
        difference: 'Diferencia',
        statusCollect: 'Estado Colección',
        statusAddress: 'Dirección Estado',
        divergentAddress: 'Abordar Divergencia',
        piecesCounted: 'Piezas Contadas',
        productDivergent: 'Produto Divergente',
      },
      statusAddress: {
        addressCode: 'Código Dirección',
        addressResetMsg: '¡Restablecimiento de dirección exitoso!',
        confirmResetMsg: 'Confirmar borrado de la dirección',
      },
      printOutBox: {
        generateBox: 'Generar',
        header: 'Encabezamiento',
        selectAgent: 'Seleccionar Agente',
        conectedSuccesfully: 'Agente conectado exitosamente',
        alertMsg: '¡Filtro por descripción o producto!',
      },
      parametrization: {
        parameterize: 'Parametrizar',
        collected: 'Recogido',
        resetProductMsg: '¡Restablecimiento del producto exitoso!',
        questionReset: '¿Quieres restablecer el producto',
        theAddress: 'en la dirección',
      },
      printWave: {
        waveCode: 'Código de Onda',
        planningDate: 'Fecha de Planificación',
      },
      printDanfe: {
        keyInvoice: 'Llave Fac',
        corporateName: 'Razón Social',
        stateRegistration: 'Inscripción Estadual',
        typeNote: 'Tipo de Nota',
        totalValue: 'Valor Total',
        issuanceDate: 'Fecha de Emisión',
      },
      printMail: {
        mail: 'Correo',
        name: 'Nombre del Recipiente',
        observation: 'Nota. Recipiente',
        description: 'Nota. Recipiente',
        number: 'Num. Remitente',
        zipCode: 'CP. Remitente',
        city: 'Ciudad del remitente',
        shipping: 'Desc. Compañía de envios',
      },
      indicators: {
        indicator: 'Indicador',
        alertSearch: '¡Filtra por un máximo de 31 días y selecciona el tipo!',
      },
    },
    components: {
      search: {
        title: 'Buscar',
        clear: 'Limpiar',
        advancedFilters: 'Filtros Avanzados',
        closeAdvancedFilters: 'Menos Filtros',
        upload: 'Subir Archivo',
        loading: 'Cargando',
        filter: 'Filtros',
      },
      table: {
        export: 'Exportar',
        entries: 'Registros',
        to: 'a',
        noResults: 'No se han encontrado resultados',
      },
      user: {
        greetings: 'Hola',
      },
      pageMode: {
        close: 'Cerrar',
        back: 'Volver',
        printOut: 'Imprimir',
      },
      confirmationModal: {
        close: 'Cerrar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      userPopper: {
        profile: 'Perfil',
        config: 'Ajustes',
      },
    },
  },
};
